@use 'sass:math';

$product-media-border-box-gap-desktop: 72px;
$product-media-border-box-gap-tablet: 16px;
$product-media-border-box-gap-mobile: 0;

//
//  Category view
//  ---------------------------------------------

.catalog-category-view .columns,
.catalogsearch-result-index .columns {
    display: flex;

    .sidebar-main {
        padding: 0;
    }
}

.product-options-wrapper {
    .configurable {
        text-align: center;

        label {
            @include cblys-overline();
        }
    }

    .fieldset {
        padding-bottom: 0;
    }

    .swatch-opt {
        margin: 0;
    }

    .super-attribute-select {
        display: none;
    }
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: block;
        max-width: 100%;
        width: 100%;
    }

    &-wrapper {
        border-radius: $border-radius__xs;
        display: block;
        height: 0;
        position: relative;
        z-index: 1;
        background: $cblys__white;
        overflow: hidden;

        @include max-screen($screen__m) {
            height: auto;
            padding-bottom: 0 !important;
        }
    }

    &-photo {
        border-radius: $border-radius__xs;
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        max-height: 100%;

        @include max-screen($screen__m) {
            position: relative;
        }
    }
}

//
//  Product view
//  ---------------------------------------------

.product-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include min-screen($screen__m) {
        flex-direction: row;
    }

    .page-title-wrapper {
        .page-title {
            margin: .5rem 0;
            @include cblys-title(30px, 34px);
        }
    }
}

.product-borderbox {
    display: flex;
    flex-direction: column;
    margin-left: math.div($product-media-border-box-gap-desktop, 2);
    width: calc(50% - #{math.div($product-media-border-box-gap-desktop, 2)});

    .manufacturer {
        line-height: 1.2;
    }
}

.product.media {
    display: flex;
    justify-content: flex-start;
    margin-bottom: $layout-indent__width * 2;
    margin-right: math.div($product-media-border-box-gap-desktop, 2);
    min-height: 672px;
    width: calc(50% - #{math.div($product-media-border-box-gap-desktop, 2)});

    .textoverlay {
        display: none;
    }

    .gallery-placeholder {
        width: 100%;

        &.gallery-multiple-images._block-content-loading {
            margin-bottom: 30px;

            .fotorama__nav-wrap {
                height: 0;
            }
        }

        .textoverlay {
            display: flex;
            max-width: 49%;
        }

        img.gallery-placeholder__image {
            border-radius: $border-radius__xs;
            display: block;
            margin: auto;
            max-width: Min(100%, 500px);
        }

        &._block-content-loading .fotorama-item {
            position: absolute !important;
            z-index: -1;
            top: 0;
            width: 100%;
        }

        &:not(._block-content-loading) {
            img.gallery-placeholder__image {
                display: none;
            }
        }
    }
}

.fotorama-item.fotorama {
    .fotorama__thumb {
        background-color: transparent;
    }

    .fotorama__thumb-border {
        border-radius: $border-radius__xs;
    }

    .fotorama__img {
        border-radius: $border-radius__xs;
    }

    .fotorama__img--full {
        border-radius: $border-radius__xs;
    }

    .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
        background-color: transparent;
        margin-top: -10px;
    }

    &.fotorama--fullscreen .fotorama__image__mask {
        display: none;
    }

    .fotorama__image__mask {
        left: 50%;
        height: 100%;
        position: relative;
        transform: translate3d(-50%, -50%, 0);
        top: 50%;
        width: 100%;
        z-index: 10;
    }
}

.product-info-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 1;

    .product-info-main-top {
        display: grid;
        margin: 9px 0 16px 0;

        grid-template-areas:
                "lipscore"
                "klarna"
                "energy";

        klarna-placement {
            grid-area: klarna;

            > div {
                width: auto !important;
            }

            *::part(osm-container), klarna-placement::part(osm-container) {
                background-color: transparent;
                border-color: #eae7e4;
                min-height: 40px;
                font-family: $font-family__base;
            }
        }

        .lipscore-widget-rating-wrapper {
            grid-area: lipscore;
            margin-top: 0;
            margin-bottom: 12px;
        }

        .energy-label {
            grid-area: energy;

            a {
                margin: 0 12px;
            }
        }
    }

    .product-info-extended {
        order: 100;
        min-width: 305px;
    }

    .description {
        font-size: math.div(16rem, 14);
        padding-bottom: 8px;
        width: 100%;
    }

    .description-read-more a {
        @include link-standard;
        font-weight: bold;
    }

    .product-add-form {
        order: 50;
        width: 100%;
    }

    .product-info-price {
        padding: 16px 0 16px 0;
    }

    .product-type-data {
        text-align: left;

        &.mobile {
            display: none;
        }
    }

    .product-info-usp {
        order: 100;

        .block {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .25rem 0;
        }

        .material-icons {
            color: $cblys__black;
            border: 1px solid $cblys__black;
            border-radius: 50%;
            margin: .5rem;
            font-size: 16px;
        }
    }

    .product-info-stock-sku {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 20;

        .material-icons.check {
            margin-right: .25rem;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                color: $text__color__muted;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .ss-main {
        .ss-single-selected {
            width: 100%;
            height: 60px;
            background: transparent;
            border: 1px solid $cblys__re-brown;
        }
    }

    .fieldset {
        margin: 0;
        padding: 1rem 0;
    }

    .field.qty {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .box-tocart {
        width: 100%;

        .fieldset {
            padding: 16px 0 24px 0;
            justify-content: center;
            width: 100%;
        }

        .actions {
            padding: 8px 0 0 0;
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 16px;

            > div {
                flex-grow: 1;
            }

            button  {
                border-radius: $border-radius__l;
            }

            .action.primary.tocart {
                margin-bottom: 16px;
                width: 100%;
            }

            #cac-add-to-cart {
                align-self: start;

                button.action.primary {
                    width: 100%;
                    margin-bottom: 0;
                }
            }
        }
    }

    .crosssell {
        margin: 0 0 8px 0;
        width: 100%;
    }
}

.product-info-main,
.sticky-addtocart {
    .price-box {
        .custom-price-box {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: end;
        }

        .custom-price-box.with-discount {
            flex-direction: row;

            .old-price {
                color: $cblys__darkbrown;
                margin-right: 1rem;
                order: 1;

                .price {
                    text-decoration: line-through;
                }
            }

            .special-price {
                color: $cblys__red;
                order: 2;
            }
        }

        .custom-price-box.with-advertised-price {
            align-items: end;
            flex-direction: column;

            .old-price {
                color: $cblys__re-light-brown;
                letter-spacing: 0.04em;
                font-size: 1.15rem;
                order: 1;
            }

            .special-price {
                font-size: 2.3rem;
                order: 2;
            }
        }

        .price-label {
            display: none;
        }
    }
}

button#product-addtocart-button {
    font-family: $font-family__base;
}

.product-options-wrapper {

    .swatch-opt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .swatch-attribute {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: 14px;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-messages {
    .page.messages {
        margin: auto;

        .message {
            margin: 8px 0;

            .product-name {
                color: $cblys__pine;
                font-weight: bold;
            }
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.icon-delivery-info {
    cursor: help;
    margin-left: 0.1rem;
}

.stock-tooltip {
    max-width: 300px;
}

.product-related-and-upsell-wrapper {
    display: flex;
    justify-content: space-between;

    .product-related-wrapper {
        width: 50%;
    }

    .product-upsell-wrapper {
        display: flex;
        justify-content: center;
        margin-left: $product-media-border-box-gap-desktop;
        width: 50%;

        &:first-child {
            margin-left: calc(50% + #{math.div($product-media-border-box-gap-desktop, 2)});
        }
    }
}

.product.info.detailed {
    display: flex;
    justify-content: center;

    .product.data.items {
        max-width: 885px;
        flex-direction: column;
    }

    .data.item {
        padding: 0 2rem 3rem;
        margin: 0;
        font-size: 18px;

        &.description {
            padding: 0 0 3rem;
        }
    }

    .data.item.title {
        padding: 2rem;

        &.description {
            padding: 2rem 0;
        }
    }

    .description-sections {
        [data-role="collapsible"] {
            border-bottom: 1px solid $cblys__black;

            &.active [data-role="trigger"]:after {
                content: '–';
            }
        }

        [data-role="trigger"] {
            cursor: pointer;
            font-weight: bold;
            padding: 12px 16px 12px 0;
            position: relative;

            &:focus-visible {
                border: 1px solid $cblys__darkgray;
                border-radius: 8px;
            }

            &:after {
                content: '+';
                font-family: monospace;
                font-size: 25px;
                font-weight: normal;
                line-height: 25px;
                position: absolute;
                right: 0;
                top: calc(50% - 14px);
            }
        }

        [data-role="content"] {
            display: none;
            padding: 0 0 12px 0;
        }
    }

    .data.item.additional {
        background-color: $cblys__beige;
        border-radius: 0 0 $border-radius__xs $border-radius__xs;

        &.title {
            border-radius: $border-radius__xs $border-radius__xs 0 0;
        }
    }

    .additional-attributes {
        width: 100%;
        margin: 0;

        @include min-screen($screen__m) {
            tbody {
                display: block;
                column-count: 2;
                column-gap: 4rem;
            }
        }

        tr {
            border-bottom: 1px solid $cblys__white;
            display: flex;
            justify-content: space-between;
            page-break-inside: avoid;
            break-inside: avoid;
        }

        th {
            font-weight: normal;
        }

        td {
            text-align: left;
        }

        .product-file {
            a {
                text-decoration: underline;
                word-break: break-all;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.product-shortcuts {
    display: flex;
    justify-content: center;
    margin: 2em 0;

    .shortcuts {
        list-style: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        @include max-screen($screen__s) {
            flex-direction: column;
        }
    }

    .item {
        min-width: 145px;
        height: 40px;
        margin: 0.5em;

        @include max-screen($screen__s) {
            margin: 0.5em 0;
        }
    }

    .item.title {
        min-width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;

        @include max-screen($screen__m) {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .button {
        @include cblys-base-button();
        letter-spacing: 0;
        padding: 10px 1em;
        border-radius: $border-radius__l;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: 12px;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Go to top button
//  ---------------------------------------------
.go-to-top {
    &__button {
        @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-color: white,
            $_icon-font-size: 1.5rem
        );
        border-radius: $border-radius__l;
        display: none;
        position: fixed;
        right: 1rem;
        bottom: 4rem;
        z-index: 1;
        flex-direction: row-reverse;
        background: black;
        color: white;
        border-color: white;
        padding: 0.5rem 1rem;
    }

    &__text {
        padding-right: 0.5rem;
        font-size: 0.8rem;
    }
}

//
//  Grouped products - product page
//  _____________________________________________

.page-product-grouped.catalog-product-view {
    .advertised-price-wrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .grouped {
        margin-bottom: 0;
    }

    .table.grouped {
        .product-item-name {
            margin-bottom: 0.75rem;
        }

        .custom-price-box {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;

            .special-price {
                font-size: 1rem;
                margin: 0;
            }

            .old-price {
                margin-left: 0;
            }

            .price {
                font-size: 1.435rem;
                line-height: 2rem;
            }
        }

        .custom-price-box.with-advertised-price {
            .old-price {
                .advertised-price-label,
                .price {
                    font-size: 1rem;
                }
            }
        }
    }

    .box-tocart {
        .fieldset {
            padding-top: 1rem;

            .actions {
                padding-top: 0;
            }
        }
    }
}

//
//  tablet large
//  _____________________________________________

@include max-screen($screen__l) {
    .product-borderbox {
        width: calc(50% - #{math.div($product-media-border-box-gap-tablet, 2)});
        margin-left: math.div($product-media-border-box-gap-tablet, 2);
    }

    .product.media {
        width: calc(50% - #{math.div($product-media-border-box-gap-tablet, 2)});
        margin-right: math.div($product-media-border-box-gap-tablet, 2);
        min-height: auto;
    }

    .product-related-and-upsell-wrapper .product-upsell-wrapper {
        margin-left: $product-media-border-box-gap-tablet;

        &:first-child {
            margin-left: calc(50% + #{math.div($product-media-border-box-gap-tablet, 2)});
        }
    }

    .data.item.content .product.attribute.description {
        flex-direction: column;

        .value {
            width: 100%;
        }

        .attribute__extra-block {
            width: 100%;
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-borderbox {
        margin-left: $product-media-border-box-gap-mobile;
        width: 100%;
    }

    .product.media {
        margin-bottom: 10px;
        margin-right: $product-media-border-box-gap-mobile;
        width: 100%;
    }

    .product-related-and-upsell-wrapper {
        flex-direction: column-reverse;

        .product-upsell-wrapper {
            margin-left: $product-media-border-box-gap-mobile;
            width: 100%;

            &:first-child {
                margin-left: $product-media-border-box-gap-mobile;
            }
        }

        .product-related-wrapper {
            margin-bottom: 1rem;
            width: 100%;
        }
    }

    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }

        .product.media {
            order: -1;
        }
    }

    .product-info-main {
        .product-info-price {
            order: -1;
            padding: 8px 0 4px 0;

            .price-box {
                .custom-price-box.with-advertised-price {
                    align-items: center;
                }
            }
        }

        .product-type-data {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
                order: -1;
            }
        }

        .field.qty {
            margin: 24px 0;
        }

        .cac-add-to-cart {
            max-width: 100%;
        }

        .crosssell {
            margin: 0 0 4px 0;
        }

        .product-messages .page.messages {
            .message {
                margin: 4px 0;
            }
        }

        .box-tocart {
            div.fieldset {
                align-items: center;
                padding-top: 8px;
            }

            .actions {
                flex-direction: column;
                padding: 4px 0 0 0;

                #cac-add-to-cart {
                    align-self: unset;
                }

                .action.primary.tocart {
                    margin-bottom: 0;
                }
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

    .product-wrapper .product-info-extended {
        min-width: 355px;
    }

    .product-info-main .product-add-form {
        max-width: 355px;
    }

    .go-to-top {
        &__button {
            font-size: 12px;
            padding: 0.5rem 1rem;
        }

        &__text {
            display: none;
        }
    }

    .product-wrapper .product-info-extended {
        min-width: 100%;
    }

    .product-info-main .product-add-form {
        max-width: 100%;
    }

    //
    //  Grouped products - product page
    //  _____________________________________________
    .page-product-grouped.catalog-product-view {
        .product-add-form {
            .grouped {
                .product-info-price {
                    padding: 0.5rem 0;
                }
            }
        }

        .product.info.detailed {
            max-width: 100%;

            .product.data.items {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main {
        .ss-main {
            .ss-single-selected {
                width: 305px;
                height: 60px;
            }

            .ss-content .ss-list {
                max-height: 400px;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }

        .product-info-main-top {
            grid-template-areas:
                "lipscore energy"
                "klarna energy";

            .energy-label {
                flex-direction: column;
                justify-content: space-between;
                margin-top: 0;

                picture {
                    margin-left: auto;
                }

                a {
                    line-height: 21px;
                    order: -1;
                    text-align: right;
                    width: 100%;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .page-layout-1column {
        .product-info-main {
            width: 100%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 100%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

@media only screen and (min-width: $screen__m) {
    .product.media .gallery-placeholder {
        &.gallery-multiple-images._block-content-loading {
            margin-bottom: 0;
        }
    }
}
