//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px !default;
$form-calendar-icon__color: $primary__color !default;

$polarcore-fieldset__background: #ffffff !default;
$polarcore-fieldset-legend__background: #ebebeb !default;

@mixin cblys-panel($_panel-background: $cblys__white) {
    background: $_panel-background;
    padding: 24px;
    border-radius: $border-radius__xs;
}

//
//  Common
//  _____________________________________________

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    margin-left: 0;

    @include min-screen($screen__m) {
        transition: 0.2s;

        &:hover {
            background-color: $cblys__beige;
        }
    }
}

input[type="radio"]:checked {
    background: $cblys__black;

}

input[type="radio"] {
    background: #ffffff;
    border: 1px solid $cblys__black;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    appearance: none;
}

input[type="checkbox"]:checked {
    background: $cblys__black;
    &:after {
        @include material-icon("check");
        color: #ffffff;
        font-size: 14px;
    }
}

input[type="checkbox"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid $cblys__black;
    width: 1rem;
    height: 1rem;
    border-radius: 2px;
    appearance: none;
}

.fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    margin: 0 2px;
    padding: 1rem;
    min-width: auto;

    & > .legend {
        display: flex;
        min-width: calc(100% + 2rem);
        margin-top: 2rem;
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 0.5rem 1rem;
        font-size: 20px;
        padding: 10px 1rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    & > .field,
    & > .fields > .field {
        margin-top: 1rem;

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {
            .label {
                display: inline;
                font-weight: normal;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

div.mage-error[generated] {
    margin-top: 7px;
}

.choice div.mage-error[generated] {
    display: inline-flex;
}

.mage-error {
    color: lighten($error__color, 5%);
}

.field .tooltip {
    @include lib-tooltip(right);
    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

select[size] {
    height: auto;
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
                $_icon-font-content    : $icon-calendar,
                $_icon-font-color      : $primary__color__lighter,
                $_icon-font-size       : $icon-calendar__font-size,
                $_icon-font-line-height: $icon-calendar__font-size,
                $_icon-font-display    : inline-block,
                $_icon-font-text-hide  : true
        );
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

textarea {
    margin: 0.5rem;
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Quantity inputs
//  -----------------------------------------

.polarcart-qty-wrapper {
    display: flex;
    justify-content: center;

    span {
        background-color: #ffffff;
        border: 1px solid transparent;
        border-radius: 50%;
        font-size: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cblys__black;
        font-weight: 400;
        height: 32px;
        width: 32px;
        padding: 2px 0 0 1px;

        &.qty-decrease {
            font-size: 22px;
            padding-bottom: 0.1rem;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .input-text {
        @extend .abs-input-qty;
        margin: 0;
        border: none;
        background-color: transparent;
        height: 30px;
    }
}

//
//  Recaptcha
//  -----------------------------------------

.field-recaptcha {
    margin: 12px 0;
}

.required-captcha.checkbox{
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
